import React, { useState, useEffect } from "react";
import axios from "axios";

function Test() {
  // const [allDomain, setAllDomain] = useState([]);
  let alpha = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  let endBy = [
    "a",
    // "aa",
    // "o",
    // "e",
    // "oo",
    // "i",
    // "ia",
    // "lia",
    // "la",
    // "lo",
    // "y",
    // "na",
    // "ka",
    // "u",
    // "ilia",
    // "lio",
    // "ilio",
    // "mo",
    // // "moz",
    // "ly",
  ];

  const [state, setState] = useState([]);
  let lettreMax = 5;
  let allPossible = [];
  let results = [];
  let extension = ".com";
  let maxLength = 6;

  let potentialDomain = [];
  useEffect(() => {
    let potentialDomain = axios({
      method: "get",
      url: `/api/generate`,
    }).then((res) => {
      console.log(res.data);
      setState(
        res.data.filter((el) => {
          return el;
        })
      );
    });
  }, []);

  let domains = [];
  let check = async () => {
    for (let index = 0; index < state.length; index++) {
      domains[index] = `${state[index]}.com`;
      console.log(domains[index]);
      if (index === state.length - 1) {
        console.log("terminer");
      } else {
        axios({
          method: "get",
          url: `/api/whois?domain=${domains[index]}`,
        })
          .then((res) => {
            // console.log(res);
            if (res.status === 200) {
              if (
                !res.data.domainName &&
                res.data.byTheFollowingTermsOfUse ===
                  "You agree that you may use this Data only"
              ) {
                console.log(domains[index] + " ce domaine est dispo");
              } else {
                console.log(domains[index] + " ce domaine n'est pas dispo");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  ////////////

  // for (let i = 0; i < lettreMax; i++) {
  //   allPossible[i] = allPossibleCombinations(alpha, i, "");
  // }
  // allPossible = allPossible.flat();

  // allPossible.map((el) => {
  //   endBy.map((end) => {
  //     results.push(`${el}${end}${extension}`);
  //   });
  // });

  let allDomains = results.filter((el) => {
    return el.length === maxLength + extension.length;
  });

  // console.log(results);

  return (
    <>
      {/* <div>total domain {allDomains.length}</div> */}

      <button
        onClick={() => {
          check();
        }}
      >
        chercher
      </button>
      {/* <ul>
        {allDomains.map((result, index) => {
          return <li key={index}>{result}</li>;
        })}
      </ul> */}
    </>
  );
}

export default Test;
